<template>
    <div>
        <div v-if="loading" class="text-center my-3">
        Loading products...
        </div>

        <div v-else>
        <v-list>
            <v-list-item v-for="product in products" :key="product.Modelo">
            <v-list-item-content>
                <v-list-item-title>
                    <h4>{{ product.Nome }}</h4>
                </v-list-item-title>
                <div style="display: flex; justify-content: space-between; margin: 1em;">
                    <div style="width: 30%;">
                        <label>Preco Total</label>
                        <v-text-field type="number" v-model="product.PrecoTotal" placeholder="Enter new PrecoTotal"></v-text-field>
                    </div>
                    <div style="width: 30%;">
                        <label>Preco Pix</label>
                        <v-text-field type="number" v-model="product.PrecoPix" placeholder="Enter new PrecoPix"></v-text-field>
                    </div>
                    <div style="width: 30%;">
                        <label>Preco Ted</label>
                        <v-text-field type="number" v-model="product.PrecoTed" placeholder="Enter new PrecoTed"></v-text-field>
                    </div>
                </div>
            </v-list-item-content>
            <v-list-item-action>
                <v-btn @click="confirmUpdate(product)" color="red">Salvar</v-btn>
            </v-list-item-action>
            </v-list-item>
        </v-list>
        </div>
    </div>
</template>

<script>
    import main from '../assets/scripts/main.js';

    export default {
        data() {
        return {
            products: [],
            loading: true,
        };
        },
        created() {
            this.fetchProducts();
        },
        methods: {
            async fetchProducts() {
                try {
                const resp = await main.getApi({}, 'mercadopago/pegarTodosProdutos');
                const response = await resp.json();
                this.products = response.corpo;
                this.loading = false;
                } catch (error) {
                console.error("Error fetching products:", error);
                this.loading = false;
                }
            },
            async confirmUpdate(product) {
                try {
                const { Modelo, PrecoTotal, PrecoPix, PrecoTed } = product;
                await main.postApi({
                    Modelo: Modelo,
                    precoTotal: PrecoTotal,
                    precoPix: PrecoPix,
                    precoTed: PrecoTed,
                }, 'mercadopago/atualizarPrecoProduto');
                // Update the displayed prices with the new values
                Object.assign(product, {
                    PrecoTotal: PrecoTotal,
                    PrecoPix: PrecoPix,
                    PrecoTed: PrecoTed,
                });
                } catch (error) {
                    console.error("Error updating price:", error);
                }
            },
        },
    };
</script>
