<template>
<div>
  <loadingTela v-if="carregando"/>
  <v-dialog v-model="login" max-width="300px"  max-height="520px" >
      <v-card style="padding: 30px !important; max-width: 100% !important">
        <strong>
              Área restrita
        </strong>
        <v-row>
          <v-col   cols="12"  md="12">
            <v-text-field label="Login" v-model="usuario"></v-text-field>
          </v-col>
          <v-col   cols="12"  md="12">
            <v-text-field label="Senha" v-model="senha" type="password"></v-text-field>
          </v-col>
      <v-row>
      <v-col   cols="12"  md="12" style="text-align: right;font-size: 15px">
      </v-col>
        </v-row>
            <v-col   cols="12"  md="12">
              <v-btn
                @click="loginConta()"
                depressed
                color="red"
                class="float-left"
                style="margin-bottom: 10px; width: 100%; color: white"
              >
                Login
              </v-btn>
            </v-col>
          </v-row>
        <v-row>

    </v-row>
    </v-card>
  </v-dialog>

  <v-card style="margin-top: 10px"  v-if="userLogado.nome != ''">
      <v-list-item>
        <v-list-item-avatar>
          <v-avatar color="red">
            <span class="white--text text-h5">{{ userLogado.icon }}</span>
          </v-avatar>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title v-text="userLogado.nome + ' ' + (userLogado.sobrenome != null ? userLogado.sobrenome : '')"></v-list-item-title>
          <v-list-item-subtitle v-text="'Meu cupom: ' + userLogado.cupom"></v-list-item-subtitle>
          <v-list-item-subtitle v-text="'Minha Gestão: ' + userLogado.cordenador"></v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-tabs  style="margin-top: 20px"
          v-if="logado"
          v-model="tab"
          background-color="transparent"
          color="basil"
          grow
        >
        <v-tab>
          Entradas
        </v-tab>
        <v-tab>
          Saídas
        </v-tab>
        <v-tab>
          Pendentes/Rejeitados
        </v-tab>
        <v-tab>
          Artigos
        </v-tab>
        <v-tab>
          Consultores
        </v-tab>
        <v-tab>
          Cupoms
        </v-tab>
        <v-tab>
          Produtos
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <adesoes :is="selecionarUsuario" />
        </v-tab-item>
        <v-tab-item>
          <adesoesSaidas :is="selecionarAdesoesSaidas" />
        </v-tab-item>
        <v-tab-item>
          <adesoesPendentes :is="selecionarAdesoesPendentes" />
        </v-tab-item>
        <v-tab-item>
          <artigos :is="selecionarartigos" />
        </v-tab-item>
        <v-tab-item>
          <consultores :is="selecionarConsultores" />
        </v-tab-item>
        <v-tab-item>
          <div>
            <v-list>
              <v-list-item
                v-for="(cupom, index) in cupoms"
                :key="index"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    {{ cupom.Cupom }}
                  </v-list-item-title>
                  <v-text-field v-model="cupom.Amount" @input="updateCupom(cupom)" label="Valor"></v-text-field>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn @click="deleteCupom(cupom.Cupom)" color="red">Deletar</v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </div>
        </v-tab-item>
        <v-tab-item>
          <produtos :is="selecionarProdutos" />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <v-snackbar
      v-model="snackbar"
    >
      {{ msgSnack }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Fechar
        </v-btn>
      </template>
    </v-snackbar>

  </div>
</template>

<script>
import main from '../assets/scripts/main.js';
import adesoes from "@/components/Adesoes";
import adesoessaidas from "@/components/AdesoesSaidas";
import adesoespendentes from "@/components/adesoesPendentes";
import precos from "@/components/Precos";
import artigos from "@/components/Artigos";
import consultores from "@/components/Consultores";
import produtos from "@/components/Produtos";

export default {
  name: 'Home',
  components: {
    adesoes: () => import("@/components/Adesoes"),
    loadingTela: () => import("@/components/layout/loadingTela"),
  },
  computed: {
    selecionarUsuario () {
        return adesoes
    },
    selecionarAdesoesSaidas () {
        return adesoessaidas
    },
    selecionarAdesoesPendentes () {
        return adesoespendentes
    },
    selecionarPrecos () {
        return precos
    },
    selecionarartigos () {
        return artigos
    },
    selecionarConsultores () {
        return consultores
    },
    selecionarCupoms () {
      return cupoms
    },
    selecionarProdutos () {
      return produtos
    },
  },
  data: () => ({
    userLogado: {
      color: '',
      icon: '',
      sobrenome: '',
      nome: '',
      cupom: '',
      cordenador: ''
    },
    carregando: false,
    snackbar: false,
    msgSnack: "",
    usuario: '',
    senha: '',
    login: true,
    logado: false,
    tab: null,
    cupoms: [],
  }),
  async created(){
    localStorage.removeItem('userLogado');
    await main.postApi({}, "mercadopago/validarPagamento");
    await this.fetchCupoms();  // Fetch cupoms on component creation
  },
  methods: {
    // Fetch cupoms from the API
    async fetchCupoms() {
      const req = { usuario: "RFC170811" };
      var resp = await main.getApi(req, "cupom/getCupoms");
      const retorno = await resp.json();
      this.cupoms = retorno.corpo;
    },

    // Update the value of a cupom
    async updateCupom(cupom) {
      console.log(cupom)
      var req = { usuario: "RFC170811", cupom_code: cupom.Cupom, cupom_value: cupom.Amount };
      await main.postApi(req, "cupom/updateCupom");
      this.fetchCupoms();
    },

    // Add a new cupom
    async addCupom(newCupom) {
      var req = { usuario: "RFC170811", cupom_code: newCupom.Cupom, cupom_value: newCupom.Amount };
      await main.postApi(req, "cupom/addCupom");
      this.fetchCupoms();
    },

    // Delete a cupom
    async deleteCupom(cupom_code) {
      await main.deleteApi({ usuario: "RFC170811", cupom_code: cupom_code }, "cupom/deleteCupom");
      this.fetchCupoms();
    },
    async loginConta(){
      this.carregando = true;
      var req = {
        usuario: this.usuario,
        senha: this.senha
      }
      var resp = await main.postApi(req, "autenticacao/loginAdmin");
      var retorno = await resp.json();
      if(retorno.sucesso){
        localStorage.setItem('cedeppeToken', retorno.corpo.Token);
        this.userLogado.nome = retorno.corpo.Nome;
        this.userLogado.sobrenome = retorno.corpo.Sobrenome;
        this.userLogado.cupom = retorno.corpo.Cupom;
        this.userLogado.cordenador = retorno.corpo.Cordenador;
        localStorage.setItem('userLogado',  JSON.stringify(this.userLogado));

        var icones = retorno.corpo.Nome.split(" ");
        var icone = "";
        icones.forEach((ico) => {
          icone = icone + ico.charAt(0)
        });

        this.userLogado.icon = icone;
        this.logarvariaveis();
      }
      else{
        this.snackbar = true;
        this.msgSnack = retorno.erros
      }
      this.carregando = false;
    },
    excluirToken(){
      localStorage.removeItem('cedeppeToken');
    },
    logarvariaveis(){
      this.logado = true;
      this.login = false;
    },
    deslogarvariaveis(){
      this.logado = false;
      this.login = true;
    },

  }
}
</script>
