<template>
  <div>
    <v-progress-linear :active="loadingTela" color="red" indeterminate absolute rounded  height="5"></v-progress-linear>
  <v-dialog
    v-model="dialogSucesso"
    persistent
    max-width="600px"
  >
    <v-card>
      <v-card-title>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-card-text>
            <strong class="text-h6">Parabéns !</strong></br>
            <p>
              Assim que realizar seu pagamento envie uma cópia de seu comprovante com Nome Completo para WhatsApp: +55 41 99802-6710.
            </p>
            <p>
              Guarde seu Nome de Usuário e Senha em um lugar seguro, estes serão utilizados para acesso à Área de Usuário, onde ali estará exposto seu Curriculum Vitae e Artigos a muitas empresas.
            </p>
            <small>Caso tenha algum problema ou duvida envie um email para: {{contato.email}} ou nos comunique pelo whatsapp: +55 41 99802-6710</small>
          </v-card-text>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          :disabled="desabilitar"
          @click="dialogSucesso = false"
        >
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>


 <!-- <v-dialog
    v-model="dialogErro"
    persistent
    max-width="600px"
  >
    <v-card>
      <v-card-title>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-card-text>

            <strong>
              Houve uma falha na validação de seus dados
            </strong>
            <p>
              Email: <b>{{this.email}}</b>
            </p>

            <medium>Envie um email para: {{contato.email}}  ou pelo whatsapp: {{contato.whatsapp}}</medium>
          </v-card-text>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <router-link :to="'/Associando'"> Voltar </router-link>
      </v-card-actions>
    </v-card>
  </v-dialog> -->

  </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import main from '../assets/scripts/main.js';
export default {
  name: "Associando",
  data: () => ({
    loadingTela: true,
    textoDialog: "",
    desabilitar: false,
    dialogSucesso: false,
    dialogErro: false,
    email: "",
    token: "",
    contato: {
      email: '',
      whatsapp: ''
    },
    payment_id: '',
    status: '',
    payment_type: ''
  }),
  async created() {
    var resp = await main.postApi({}, "mercadopago/validarPagamento");
    var retorno = await resp.json();
    this.loadingTela = false;
    this.dialogSucesso = true

    // //pegaInformacoes de contato
    var req2 = { setor: 'financeiro'}
    var resp2 = await main.postApi(req2, "contatos/pegarContatos");
    var retorno2 = await resp2.json();
    if(retorno2.sucesso){
      this.contato.email = retorno2.corpo.Email;
      this.contato.whatsapp = retorno2.corpo.WhatsApp;
    }

    await this.criarUsuario();
  },
  methods: {
    async criarUsuario(){
      var req = JSON.parse(localStorage.getItem("user_account"))
      var resp = await main.postApi(req, "admin/criarUsuario");
      var retorno = await resp.json();
      if(retorno.sucesso){};
    },
    async fichacadastral(){
      var req = {
        email: this.email.replace('%40', '@'),
        token: this.token
      }
      var resp = await main.postApi(req, "autenticacao/getCodigoGratuito");
      var retorno = await resp.json();
      if(retorno.sucesso){
        var html = " <html><body><p>Parabéns! por ser cadastrar na CEDESPPE, acesso o link: https://cedeppe.global/NovoAssociado e faça seu cadastro</p></body></html>"
        firebase.firestore().collection('mail').add({
          to: this.email.replace('%40', '@'),
          message: {
            subject: 'CEDESPPE - Código de cadastro',
            html: html,
          },
        })
      }
    },
    redirecionar(){
      window.location.assign("https://www.adm.cedeppe.com/cadastro/");
    },
  }
};
</script>
